<template>
  <div class="project__wrapper">
    <div class="projects__inner">
      <div class="projects__head mb-4">
        <Select2 placeholder="(Все)" class="select2 m-0 w-100" v-model="industryId" :options="industryList" />
        <ButtonStock class="ml-2" title="Выбрать" @click="loadItems" />
      </div>
      <div class="table-component notif">
        <table>
          <thead>
            <tr>
              <th>Название</th>
              <th>Инициатор</th>
              <th>Отрасль</th>
              <th>Статус</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="items != null && items.length > 0">
              <tr v-for="item in items" :key="item.id" @dblclick="viewCard(item.id)">
                <td :title="item.name">
                  {{ item.name }}
                </td>
                <td :title="item.participantsDomesticStr">
                  {{ item.participantsDomesticStr }}
                </td>
                <td width="20%" :title="item.industry.name">
                  <div
                    v-if="item.industry.picture"
                    class="imagePreviewWrapper"
                    :style="{ 'background-image': `url(${item.industry.picture})` }"
                  ></div>
                  <span v-else>{{ item.industry.name }}</span>
                </td>
                <td :title="item.status.name">
                  <IconComponent v-if="item.statusId == 1" name="goodSigh" />
                  <IconComponent v-else-if="item.statusId == 2" name="refreshSigh" />
                  <IconComponent v-else-if="item.statusId == 4" name="refreshSigh" />
                  <IconComponent v-else name="grayGoodSigh" />
                </td>
              </tr>
            </template>
            <tr v-else-if="items == null">
              <td colspan="4">Загрузка...</td>
            </tr>
            <tr v-else>
              <td colspan="4">Ничего не найдено</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ButtonStock class="w-100 mt-2" title="Все проекты" @click="goTo" />
    </div>
    <div class="chart__wrapper">
      <p class="mb-5" v-if="items !== null">
        Всего проектов – {{ total.all }},
        <br />
        в процессе реализации – {{ total.inProgress }},
        <br />
        реализовано – {{ total.done }},
        <br />
        заморожено – {{ total.freeze }}
      </p>
      <ColumnChartHorizontal
        v-if="projects.length"
        :height="500"
        index-axis="y"
        :bar-percentage="1"
        :colors="[
          '#00B2FF',
          '#00B2FF',
          '#00B2FF',
          '#00B2FF',
          '#00B2FF',
          '#00B2FF',
          '#00B2FF',
          '#00B2FF',
          '#00B2FF',
          '#00B2FF',
          '#00B2FF',
          '#00B2FF',
          '#00B2FF',
          '#00B2FF',
          '#00B2FF',
          '#00B2FF',
        ]"
        :chart-data="projects.map((item) => item.data)"
        :labels="projects.map((item) => item.name)"
        v-on:clickedBar="onClickedBar"
      />
    </div>
  </div>
</template>

<script>
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ColumnChartHorizontal from '@/components/column-chart-horizontal';

  import API_NSI from '@/modules/nsi/api/nsi';

  import API from '../api/widgetElement';

  export default {
    name: 'Widget16',
    components: {
      IconComponent,
      ButtonStock,
      ColumnChartHorizontal,
    },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    watch: {
      countryId: function (/*newValue*/) {
        this.loadItems();
        this.loadForGraph();
      },
    },
    data() {
      return {
        items: null,
        industryList: [],
        industryId: null,
        projects: [],
        total: {
          all: 0,
          inProgress: 0, //status = 2
          done: 0, //status = 1
          freeze: 0, //status = 4
        },
      };
    },
    created() {
      if (this.countryId > 0) {
        this.loadItems();
        this.loadForGraph();
      }
      API_NSI.search('NsiIndustry', { pageNumber: 1, pageSize: 0 }).then((response) => {
        if (response.data) {
          this.industryList = response.data.items.map((o) => {
            return { text: o.name, id: o.id };
          });
        }
      });
    },
    methods: {
      loadItems() {
        this.items = null;
        API.getMpkProject({
          countryIds: [this.countryId],
          industryIds: this.industryId > 0 ? [this.industryId] : null,
        }).then((response) => {
          this.items = response.data;
        });
      },
      loadForGraph() {
        API.getMpkProject({
          pageNumber: 1,
          pageSize: 0,
          countryIds: [this.countryId],
        }).then((response) => {
          this.projects = [];
          this.$nextTick(() => {
            var resReduced = response.data.reduce((grouped, item) => {
              const key = item['industryId'];
              if (!grouped[key]) {
                grouped[key] = [];
              }
              // Add object to list for given key's value
              grouped[key].push(item);
              return grouped;
            }, {});
            var resArr = [];
            for (const [key, value] of Object.entries(resReduced)) {
              let dictItemname = this.industryList.find((d) => d.id == key).text;
              resArr.push({ name: dictItemname, data: value.length });
            }
            this.projects = resArr.sort(function (a, b) {
              return b.data - a.data;
            });

            this.total.all = response.data.length;
            this.total.inProgress = response.data.filter((x) => x.statusId == 2).length;
            this.total.done = response.data.filter((x) => x.statusId == 1).length;
            this.total.freeze = response.data.filter((x) => x.statusId == 4).length;
          });
        });
      },
      goTo() {
        this.$router.push({ name: 'Projects' });
      },
      viewCard(id) {
        this.$router.push({ name: 'ProjectCard', params: { id: id } });
      },
      onClickedBar(index) {
        let industryId;
        if (index >= 0) {
          const industry = this.projects[index].name;
          industryId = this.industryList.find((i) => i.text == industry).id;
        }
        this.$router.push({
          name: 'Projects',
          params: {
            countryId: [this.countryId],
            industryId: industryId ? [industryId] : null,
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .imagePreviewWrapper {
    width: 14px;
    height: 14px;
    display: block;
    background-size: cover;
    background-position: center center;
  }

  .select2::v-deep {
    outline: none;
    max-width: 161px;
    width: 100%;
    @media (max-width: 576px) {
      max-width: 100%;
      margin: -12px 8px 12px 8px;
    }
    // Контейнер ширина
    .select2-container {
      outline: none;
      min-width: 161px;
      width: 100% !important;
      // Текст
      .select2-selection__rendered {
        outline: none;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 40px;
        color: #343c44;
      }
    }

    // Контейнер высота
    .select2-container--default .select2-selection--single {
      outline: none;
      height: calc(2.25rem + 6px) !important;
      border-radius: 2px !important;
      border: 1px solid #d6e4ec;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
      outline: none;
      top: 50% !important;
      transform: translateY(-50%) !important;
    }

    // Стрелочка
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      outline: none;
      border-color: unset;
      border-style: unset;
      border-width: unset;
      width: 10px;
      height: 10px;
      border-radius: 1px;
      border-left: 3px solid #343c44;
      border-bottom: 3px solid #343c44;
      transform: rotate(315deg);
      margin-left: -10px;
      margin-top: -6px;
    }

    // Плейсхолдер
    .select2-container--default .select2-selection--single .select2-selection__placeholder {
      outline: none;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #343c44;
    }
  }

  .projects__inner {
    padding: 18px;
    max-width: 45%;
  }
  .projects__head {
    display: flex;
    align-items: center;
  }
  .project__wrapper {
    min-width: 1024px;
    display: flex;
    justify-content: space-between;
  }

  .chart__inner {
    background: #ffffff;
    padding: 42px;
    box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
  }
  .chart__wrapper {
    padding: 18px;
    width: 100%;
  }
  .chart-stacked__wrapper {
    padding: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 768px) {
      min-width: 768px;
      align-items: flex-start;
    }
    p {
      margin-right: 130px;
      margin-bottom: 0;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #343c44;
    }
  }
</style>
